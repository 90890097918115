import React from "react"

export interface ButtonProps {
  text: string
  className?: string
  onClick?: () => void
  secondary?: boolean
  loading?: boolean
  disabled?: boolean
  to?: string
  newTab?: boolean
}

export const Button: React.SFC<ButtonProps> = ({
  text,
  className,
  onClick,
  secondary,
  loading,
  disabled,
  to,
  newTab,
}) => {
  return !to ? (
    <button
      className={`${className} inline-block py-4 px-8 leading-none focus:outline-none ${
        secondary
          ? "border-gray-300 bg-white text-gray-700 hover:bg-gray-50"
          : loading || disabled
          ? "text-white bg-gray-400"
          : "text-white bg-secondary-600 hover:bg-secondary-700"
      } font-semibold rounded shadow text-center`}
      onClick={onClick}
      disabled={loading || disabled}
    >
      {!loading ? (
        text
      ) : (
        <div className="flex justify-center">
          <svg
            className="animate-spin h-4 w-4 mr-3 text-white"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      )}
    </button>
  ) : (
    <a
      className={`${className} inline-block py-4 px-8 leading-none ${
        secondary
          ? "border-gray-300 bg-white text-gray-700 hover:bg-gray-50"
          : loading
          ? "text-white bg-gray-400"
          : "text-white bg-secondary-600 hover:bg-secondary-700"
      } font-semibold rounded shadow text-center`}
      href={to}
      target={newTab ? "_blank" : ""}
      rel={newTab ? "noopener noreferrer" : ""}
    >
      {!loading ? (
        text
      ) : (
        <div className="flex justify-center">
          <svg
            className="animate-spin h-4 w-4 mr-3 text-white"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      )}
    </a>
  )
}
