import React from "react"
import { Footer } from "./Footer"
import { Navigation } from "./Navigation"
import SEO from "./SEO"

export interface LayoutProps {
  children: JSX.Element[] | JSX.Element
  title?: string
  description?: string
  faq?: { question: string; answer: string }[]
}

const Layout: React.SFC<LayoutProps> = ({
  children,
  title,
  description,
  faq,
}) => {
  return (
    <div>
      <SEO title={title} description={description} faq={faq} />
      <div className="bg-secondary-500 text-white w-full">
        <div className="container md:mx-auto py-6 mx-6">
          <p>
            Die DPTA Die Psychotherapie App UG (haftungsbeschränkt) befindet
            sich in Auflösung und ist daher <b>nicht mehr erreichbar</b>.
          </p>
        </div>
      </div>
      <Navigation />
      <main>{children}</main>
      <Footer />
      {/* <script
        async
        type="text/javascript"
        src="https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/bd99dd20547b414285c38d0d71e3de583e495cc4abf74fdeacfeb43ce6008361.js"
      ></script> */}
    </div>
  )
}

export default Layout
