import React, { useState } from "react"
import Logo from "./../images/Logo_Lila.png"
import { NavigationLinks } from "./Navigation"
import { Link } from "gatsby"

export interface FooterProps {}

export const Footer: React.SFC<FooterProps> = () => {
  const year = new Date().getFullYear().toString()
  const [showExpanedFunctions, setShowExpanedFunctions] = useState(false)
  return (
    <footer className="px-4">
      <div className="container mx-auto flex flex-wrap items-center py-4 border-b">
        <div className="w-2/5 lg:w-1/5 text-center lg:text-left">
          <Link to="/">
            <img
              src={Logo}
              alt="Logo Die Psychotherapie App"
              title="Logo Die Psychotherapie App"
              width="170"
            />
          </Link>
        </div>
        <div className="w-3/5 lg:w-4/5 mt-4 lg:mt-0 text-right">
          {NavigationLinks.map(({ link, name, sublinks }) => (
            <>
              <Link
                key={name}
                className="block lg:inline-block mt-4 text-xl lg:text-base text-right lg:text-left lg:mt-0 mr-0 lg:mr-10 text-primary-600 hover:text-primary-800"
                to={link}
                onClick={(e) => {
                  if (sublinks === undefined) return
                  e.preventDefault()
                  setShowExpanedFunctions(!showExpanedFunctions)
                }}
                partiallyActive={sublinks !== undefined}
                activeClassName="text-secondary-600 lg:text-primary-600 border-b-0 lg:border-b-2 border-primary-600"
              >
                {sublinks !== undefined && showExpanedFunctions && (
                  <div className="shadow-lg relative mb-2 p-4 flex-row space-y-2">
                    {sublinks.map((sublink) => (
                      <div className="w-full">
                        <Link to={sublink.link}>{sublink.name}</Link>
                      </div>
                    ))}
                  </div>
                )}
                <span className="flex">
                  {name}{" "}
                  {sublinks !== undefined && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  )}
                </span>
              </Link>
            </>
          ))}
        </div>
      </div>
      <div className="py-4 text-center">
        <span className="block md:inline-block mb-4 md:mb-0 mx-3">
          &copy; {year} Die Psychotherapie App
        </span>
        <Link
          className="inline-block mx-4 text-primary-600 hover:text-primary-800 no-underline"
          to="/impressum"
          activeClassName="text-secondary-600 lg:text-primary-600 border-b-0 lg:border-b-2 border-primary-600"
        >
          Impressum
        </Link>
        <Link
          className="inline-block mx-4 text-primary-600 hover:text-primary-800 no-underline"
          to="/datenschutzerklaerung"
          activeClassName="text-secondary-600 lg:text-primary-600 border-b-0 lg:border-b-2 border-primary-600"
        >
          Datenschutzerklärung
        </Link>
        <Link
          className="inline-block mx-4 text-primary-600 hover:text-primary-800 no-underline"
          to="/widerrufsbelehrung"
          activeClassName="text-secondary-600 lg:text-primary-600 border-b-0 lg:border-b-2 border-primary-600"
        >
          Widerrufsbelehrung
        </Link>
        <Link
          className="inline-block mx-4 text-primary-600 hover:text-primary-800 no-underline"
          to="/datenschutz-app"
          activeClassName="text-secondary-600 lg:text-primary-600 border-b-0 lg:border-b-2 border-primary-600"
        >
          Datenschutz App
        </Link>
        <Link
          className="inline-block mx-4 text-primary-600 hover:text-primary-800 no-underline"
          to="/agb-therapeut_innen"
          activeClassName="text-secondary-600 lg:text-primary-600 border-b-0 lg:border-b-2 border-primary-600"
        >
          AGB Therapeut*innen
        </Link>
        <Link
          className="inline-block mx-4 text-primary-600 hover:text-primary-800 no-underline"
          to="/agb-patient_innen"
          activeClassName="text-secondary-600 lg:text-primary-600 border-b-0 lg:border-b-2 border-primary-600"
        >
          AGB Patient*innen
        </Link>
      </div>
    </footer>
  )
}
