import React, { useState } from "react"
import { Link } from "gatsby"
import Logo from "./../images/Logo_Lila.png"
import { Button } from "./text/Button"

export interface NavigationProps {}

export const NavigationLinks = [
  { name: "Startseite", link: "/" },
  {
    name: "Funktionen",
    link: "/funktionen",
    sublinks: [
      {
        name: "Psychotherapeut*innen",
        link: "/funktionen/psychotherapeut_innen",
      },
      {
        name: "Patient*innen",
        link: "/funktionen/patient_innen",
      },
    ],
  },
  { name: "FAQ", link: "/faq" },
  /* { name: "Neuigkeiten", link: "/neuigkeiten" }, */
  { name: "Über uns", link: "/ueber-uns" },
  { name: "Kontakt", link: "/kontakt" },
]

export const Navigation: React.SFC<NavigationProps> = () => {
  const [showMenu, setShowMenu] = useState(false)
  const [showExpanedFunctions, setShowExpanedFunctions] = useState(false)

  return (
    <>
      <nav className="px-4">
        <div className="container mx-auto flex flex-wrap items-center justify-between px-full py-4">
          <div className="flex flex-shrink-0 mr-6">
            <Link
              className="text-xl text-primary-600 font-semibold font-heading"
              to="/#"
            >
              <img
                src={Logo}
                alt="Logo Die Psychotherapie App"
                title="Logo Die Psychotherapie App"
                width="170"
              />
            </Link>
          </div>
          <div className="block lg:hidden">
            <button
              className="navbar-burger flex items-center py-2 px-3 text-primary-600 rounded border border-primary-600 focus:outline-none"
              onClick={() => {
                setShowMenu(!showMenu)
              }}
            >
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
              </svg>
            </button>
          </div>
          <div
            className={`navbar-menu ${
              showMenu ? "flex" : "hidden"
            } lg:flex lg:flex-grow lg:items-center w-full justify-end lg:w-auto`}
          >
            <div className="lg:ml-auto">
              {NavigationLinks.map(({ link, name, sublinks }) => (
                <>
                  <Link
                    key={name}
                    className="block lg:inline-block mt-4 text-xl lg:text-base text-right lg:text-left lg:mt-0 mr-0 lg:mr-10 text-primary-600 hover:text-primary-800"
                    to={link}
                    onClick={(e) => {
                      if (sublinks === undefined) return
                      e.preventDefault()
                      setShowExpanedFunctions(!showExpanedFunctions)
                    }}
                    partiallyActive={sublinks !== undefined}
                    activeClassName="text-secondary-600 lg:text-primary-600 border-b-0 lg:border-b-2 border-primary-600"
                  >
                    <span className="flex">
                      {name}{" "}
                      {sublinks !== undefined && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          className="h-6 w-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                      )}
                    </span>
                    {sublinks !== undefined && showExpanedFunctions && (
                      <div className="shadow-lg relative lg:absolute mt-2 p-4 flex-row space-y-2">
                        {sublinks.map((sublink) => (
                          <div className="w-full">
                            <Link to={sublink.link}>{sublink.name}</Link>
                          </div>
                        ))}
                      </div>
                    )}
                  </Link>
                </>
              ))}
              <Button
                text="Zur App"
                to="https://app.diepsychotherapieapp.de"
                className="mt-4 lg:mt-0"
                newTab
              />
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}
