import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import Logo from "../images/Logo_Lila.png"

export interface SEOProps {
  title?: string
  description?: string
  image?: string
  faq?: { question: string; answer: string }[]
}

const SEO: React.SFC<SEOProps> = ({ title, description, image, faq }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  const logoJSONLD = {
    "@context": "https://schema.org",
    "@type": "Organization",
    url: siteUrl,
    logo: Logo,
    email: "jonas@diepsychotherapieapp.de",
    name: "Die Psychotherapie App",
    telephone: "+4917643746921",
  }

  const faqJSONLD = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faq?.map((item) => {
      return {
        "@type": "Question",
        name: item.question,
        acceptedAnswer: {
          "@type": "Answer",
          text: item.answer,
        },
      }
    }),
  }

  return (
    <Helmet
      title={seo.title}
      titleTemplate={titleTemplate}
      htmlAttributes={{ lang: "de" }}
    >
      <script type="application/ld+json">{JSON.stringify(logoJSONLD)}</script>
      {faq && (
        <script type="application/ld+json">{JSON.stringify(faqJSONLD)}</script>
      )}
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
    </Helmet>
  )
}

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
      }
    }
  }
`
